@import "../css/variables.module";

.excalidraw {
  .Avatar {
    width: 1.25rem;
    height: 1.25rem;
    position: relative;
    border-radius: 100%;
    outline-offset: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1;

    &-img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      border: 1px solid var(--avatar-border-color);
      border-radius: 100%;
    }
  }
}
