@import "../../css/variables.module";

.excalidraw {
  .OverwriteConfirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    isolation: isolate;

    h3 {
      margin: 0;

      font-weight: 700;
      font-size: 1.3125rem;
      line-height: 130%;
      align-self: flex-start;

      color: var(--text-primary-color);
    }

    &__Description {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      gap: 1rem;

      @include isMobile {
        flex-direction: column;
        text-align: center;
      }

      padding: 2.5rem;

      background: var(--color-danger-background);
      border-radius: 0.5rem;

      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;

      color: var(--color-danger-color);

      &__spacer {
        flex-grow: 1;
      }

      &__icon {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2.5rem;
        background: var(--color-danger-icon-background);
        width: 3.5rem;
        height: 3.5rem;

        padding: 0.75rem;

        svg {
          color: var(--color-danger-icon-color);
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &.OverwriteConfirm__Description--color-warning {
        background: var(--color-warning-background);
        color: var(--color-warning-color);

        .OverwriteConfirm__Description__icon {
          background: var(--color-warning-icon-background);
          flex: 0 0 auto;

          svg {
            color: var(--color-warning-icon-color);
          }
        }
      }
    }

    &__Actions {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-items: stretch;
      justify-content: center;
      gap: 1.5rem;

      @include isMobile {
        flex-direction: column;
      }

      &__Action {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;
        gap: 0.75rem;
        flex-basis: 50%;
        flex-grow: 0;

        &__content {
          height: 100%;
          font-size: 0.875rem;
          text-align: center;
        }

        h4 {
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 130%;

          margin: 0;

          color: var(--text-primary-color);
        }
      }
    }
  }
}
