body {
  height: calc(
    100% + env(safe-area-inset-top, 0) + env(safe-area-inset-bottom, 0)
  ) !important;
}
.modal-box {
  height: 500px;

  .box {
    width: 100%;
    min-width: 320px;
    max-width: calc(100% - 300px);
    height: 700px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}

.ant-tabs-tab-remove {
  color: #d1cbcb !important;

  &:hover {
    color: #ff4d4f !important;
  }
}

.ant-tabs-tab {
  background-color: #000 !important;
  border-color: #222 !important;
  color: #d1cbcb !important;
}

.ant-tabs-nav::before {
  border-color: #222 !important;
}

.ant-space-item {
  color: #d1cbcb !important;
}

.tab-box {
  width: 100%;
  height: 100%;
  background-color: #000;
  box-sizing: border-box;
  padding-top: env(safe-area-inset-top);

  .left-tab {
    width: 0;
    height: 100%;
    transition: all 0.4s;
    position: relative;

    .show-butn {
      cursor: pointer;
      position: absolute;
      top: 43%;
      height: 50px;
      left: 100%;
      font-size: 10px;
      width: 18px;
      background-color: #000;
      border: 1px solid #222;
      display: flex;
      border-radius: 0 10px 10px 0;
      color: #d1cbcb;
      justify-content: center;
      align-items: center;
      margin: 0;
      z-index: 1000;
    }

    .tab-main {
      border-right: 1px solid #222;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .box {
        width: calc(100vw * 0.18);
      }
    }

    .tab-title {
      height: 60px;
      border-bottom: 1px solid #222;
      font-size: 22px;
      font-weight: bold;
      color: #d1cbcb;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .list {
      width: 100%;
      height: calc(100% - 60px);
      overflow-y: scroll;
      overflow-x: hidden;

      .item {
        height: 48px;
        border-bottom: 1px solid #222;
        padding: 0 20px;
        box-sizing: border-box;

        .title {
          cursor: pointer;
          font-size: 18px;
          color: #d1cbcb;

          &:hover {
            color: #40a9ff;
          }
        }

        .remove-butn {
          cursor: pointer;
          color: #d1cbcb;

          &:hover {
            color: #ff4d4f;
          }
        }
      }
    }

    .list::-webkit-scrollbar {
      width: 0 !important;
    }

    .list {
      -ms-overflow-style: none;
    }

    .list {
      overflow: -moz-scrollbars-none;
    }
  }

  .tab-show {
    width: 18%;
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .tab-box {
    .left-tab {
      .tab-main {
        .box {
          width: calc(100vw * 0.32);
        }
      }
    }

    .tab-show {
      width: 32%;
    }
  }
}

@media screen and (max-width: 600px) {
  .tab-box {
    .left-tab {
      .tab-main {
        .box {
          width: calc(100vw * 0.5);
        }
      }
    }

    .tab-show {
      width: 50%;
    }
  }
}

.flex {
  display: flex;
}

.flex-center {
  align-items: center;
}

.flex-between {
  justify-content: space-between;
}

.content-tab {
  flex: 1;
  height: calc(100% - env(safe-area-inset-bottom));

  .show-butn {
    cursor: pointer;
    position: absolute;
    height: 18px;
    right: 50px;
    font-size: 10px;
    width: 50px;
    background-color: #000;
    border: 1px solid #222;
    display: flex;
    border-radius: 0 0 10px 10px;
    color: #d1cbcb;
    justify-content: center;
    align-items: center;
    margin: 0;
    z-index: 1000;
  }
}

.agreement {
  padding: 20px;
}
