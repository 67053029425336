@import "open-color/open-color.scss";
@import "../css/variables.module";

.excalidraw {
  .App-toolbar {
    &.zen-mode {
      .ToolIcon__keybinding,
      .HintViewer {
        display: none;
      }
    }

    &__divider {
      width: 1px;
      height: 1.5rem;
      align-self: center;
      background-color: var(--default-border-color);
      margin: 0 0.25rem;
    }
  }

  .App-toolbar__extra-tools-trigger {
    box-shadow: none;
    border: 0;

    &:active {
      background-color: var(--button-hover-bg);
      box-shadow: 0 0 0 1px
        var(--button-active-border, var(--color-primary-darkest)) inset;
    }
  }

  .App-toolbar__extra-tools-dropdown {
    margin-top: 0.375rem;
    right: 0;
    min-width: 11.875rem;
  }
}
